.App {
  text-align: center; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

nav {
  background-color: black;
  padding: 0px 30px 30px 30px;
  font-size: 25px;
  height: 30px;
  margin:0;
  vertical-align: middle;
}

nav img {
  height: 40px;
  padding: 0;
  margin: 0;
}

nav a{
  text-decoration: none;
  color: white;
  margin: 0 10px;
  position: relative;
  top: 10%;
  bottom: 10%;
  overflow: hidden;
  vertical-align: middle;
  font-family: 'Arial Narrow', Arial, sans-serif;
}


nav a:hover{
  text-decoration: underline;
}

nav .vline {
  margin: 0 15;
}

@media only screen and (max-width: 590px){
  nav button{
    float: right;
    background-color: black;
    color: white;
    border: none;
    font-size: 40px;

  }

  nav button:active{
    background-color: red;
    color: black;
  }

  nav #mobileLinks{
    background-color: black;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1;
  }

  nav #mobileLinks a{
    font-size: 50px;
    padding: 0 20px;
  }

  nav a:hover{
    text-decoration: underline;
  }
}


#Homepage_Banner_Text a, #signupButton{
  text-decoration: none;
}

#Homepage #Homepage_Banner div{
  display: inline-block;
  margin: auto;
}

#Homepage #Homepage_Banner, #Homepage_Banner_Image{
  height: 450px;
}

#Homepage #Homepage_Banner_Text{
  float: left;
}

#Homepage #Homepage_Banner_Image{
  float:  right;
}

#Homepage #Homepage_Banner #Homepage_Banner_Text{
  margin-top: 80px;
  margin-left: 10%;
}

#Homepage #Homepage_Banner #Homepage_Banner_Text h1{
  font-family:'Times New Roman', Times, serif;
  font-size: 48px;
  margin-bottom: 0;
}

#Homepage #Homepage_Banner #Homepage_Banner_Text p{
  width: 70%;
  font-size: 18px;
}

#Homepage #Homepage_Banner #Homepage_Banner_Text a, #signupButton{
  background: #fd5800;
  padding: 15px 55px;
  border-radius:3px;
  display:inline-block;
  color: white;
}



#Homepage #Homepage_Banner #Homepage_Banner_Image #banner_square{
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

#Homepage #Homepage_Banner #Homepage_Banner_Image #banner_tall {
  position: absolute;
  border: 10px orange solid;
  background-color: orange;
  top: 80px;
  right: 22%;
  height: 50%;
}

@media only screen and (max-width:1151px){
  #Homepage_Banner_Image img{
    display: none;
  }

  #Homepage_Banner_Text{
    width: 90%;
    margin: auto;
    text-align: center;
  }

  #Homepage_Banner_Text p{
    width: 100%;
    margin: auto;
    padding: 30px 0;
  }

  #Homepage #Homepage_Banner #Homepage_Banner_Text{
    margin: auto;
    margin-top: 20px;
  }

  #Homepage #Homepage_Banner_Text{
    float: none;
    width: 100%;
  }

  #Homepage #Homepage_Banner, #Homepage_Banner_Image{
    height: 350px;
  }

  

}

#Homepage #Slogan{
  background-color: #800000;
  color:white;
  margin-bottom: 100px;
  padding: 35px;
}

#Homepage #Slogan h2{
  margin:auto;
  text-align:center;
  font-size:40px;
  font-family:'Times New Roman', Times, serif;
}

#Homepage #Slogan #second_line{
  font-style:italic;
}

#tv-wrapper {
  max-width:600px;
  max-height:338px;
  width:90vw;
  height:50.85vw;
  margin:0 auto;
  margin-bottom: 100px;
}


#trailer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #777;
  display: block;
  border-style:none;
  margin-bottom: 100px;
}

@media only screen and (max-width: 470px){
  #Homepage #Slogan{
    margin-top: 30px;
  }
}


#HomeStairs{
  max-width: 1230px;
  margin:auto;
}


#HomeStairs .StairRight, .StairLeft{
  height: 550px;
  position:relative;
  margin-bottom: 0px;
}


.SR-Pic, .SL-Pic, .SR-TextWrapper, .SL-TextWrapper{
  display: inline-block;
  width: 50%;
  height:100%;
  top:0;
  vertical-align:top;
  position:relative;
}



#HomeStairs .StairRight .SR-Pic, .SL-Pic{
  text-align: right;
  height:100%;
}

.SL-Pic{
  text-align: left;
  height:100%;
}

#HomeStairs .StairRight img, .StairLeft img{
  height:100%;
}

#HomeStairs .StairRight .SR-TextWrapper h2, .SL-TextWrapper h2{
  font-size: 40px;
  line-height:48px;
  overflow-wrap:break-word;
  font-weight: normal;
  width: 90%;
  height: auto;
  margin: 0 45px;
  padding: 10px;
  position: relative;
}

#HomeStairs .StairRight .SR-TextWrapper p, .SL-TextWrapper p{
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
  width: 80%;
  height: auto;
  margin: 0 45px;
  padding: 10px;
  position: relative;

}


#HomeStairs a{
  color: orange;
  font-size: 25px;
  margin-left: 47px;
  padding: 0 10px;
}
/* This is the only way I could find to make the text look somewhat vertically centered */
.SR-Text, .SL-Text{
  margin-top: 10%;
}


@media only screen and (max-width: 1110px){
  .SR-Pic, .SL-Pic, .SR-TextWrapper, .SL-TextWrapper{
    display: block;
  }

  #HomeStairs .StairRight, .StairLeft{
    height: auto;
  }

  .SR-TextWrapper{
    margin: auto;
    width: 90%;
    margin-bottom: 10%;
    margin-top: 0px;
  }
  
  .SR-Pic{
    width: 70%;
    margin: auto;
  }

  .StairRight img{
    width: 100%;
    height: auto;
  }

  .SR-Text{
    margin-top: 0px;
    text-align: center;
  }

  #HomeStairs .StairRight .SR-TextWrapper p{
    padding: 0;
  }

  #HomeStairs .StairRight .SR-TextWrapper h2{
    margin-top: 0px;
    margin: auto;
    padding: 0;
  }

  .StairRight{
    margin-top: 50px;
  }

  #HomeStairs .StairRight .SR-TextWrapper p, .SL-TextWrapper p{
    margin: auto;
  }
}
#rotation{
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  margin:auto;
}

#rotation #rot-head #rot-text, #rot-buttons{
  display:inline-block;
  margin: 0 10px;

}

#rot-text {
  position: absolute;
  left: 0;
}

#rot-buttons {
  position: absolute;
  right: 0;
}

#rotation #rot-head{
  width: 100%;
  margin:auto;
  position: relative;
}

#rot-head-wrapper{
  height: 60px;
  margin-bottom: 10px;
  border-bottom: 3px black solid;
  margin-top: 20px;
}

#rot-buttons a, #rot-text h2{
  font-size: 50px;
  font-weight: normal;
  font-family: "Red Hat",Sans-serif;
  margin:0;
}

#rot-buttons a{
  padding: 0;
  border: none;
  text-decoration: none;
  background: none;
  margin-left: 30px;
  color: grey;
}

#rotating{
  width: 167%;
  transform: translate(-20%,0);
}

.rotPart{
  display:inline-block;
  margin: 1.8%;
  width: 16.2%;
  overflow-wrap: break-word;
  vertical-align : top;
  text-align: center;
}

.rotText{
  text-align: center;
}


.rotPart img{
  width: 100%;
}

.rotPart h2{
  display:inline-block;
  white-space: normal;
  max-width:100%;
  margin:0;
  text-align:center;
  overflow:hidden;
}

.rotPart p{
  white-space: normal;
  max-width:100%;
  margin:0;
  text-align:center;
}



/*The next two parts are used to control animation*/
.rotPart[sliding = "L"]{
  transform: translate(-122%,0);
  -webkit-transform: translate(-122%,0); /** Chrome & Safari **/
  -o-transform: translate(-122%,0); /** Opera **/
  -moz-transform: translate(-122%,0); /** Firefox **/
  transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out; /** Chrome & Safari **/
  -moz-transition: all 2s ease-in-out; /** Firefox **/
  -o-transition: all 2s ease-in-out; /** Opera **/
}

#fourohfour{
  margin-bottom: 300px;
}

.rotPart[sliding = "R"]{
  transform: translate(122%,0);
  -webkit-transform: translate(122%,0); /** Chrome & Safari **/
  -o-transform: translate(122%,0); /** Opera **/
  -moz-transform: translate(122%,0); /** Firefox **/
  transition: all 2s ease-in-out;
  -webkit-transition: all 2s ease-in-out; /** Chrome & Safari **/
  -moz-transition: all 2s ease-in-out; /** Firefox **/
  -o-transition: all 2s ease-in-out; /** Opera **/
}
@media only screen and (max-width: 1050px){
  #rotation{
    width: 90%;
  }

  #rotating{
    width: 500%;
  }

  .rotPart p{
    font-size: 20px;
  }

  .rotPart h2{
    font-size: 30px;
  }
}

#foot-wrapper{
  background-color: black;
  margin-top: 20px;
  bottom: 0;
}

#footer{
  background-color: black;
  color:white;
  margin:0;
  width: 90%;
  margin: auto;
}

#footer h2{
  margin:0;

}

.footPart{
  width: calc(100% / 4);  
  display: inline-block;
  vertical-align: top;   
  margin:2%;    
  padding:20px;
}

.footPart h3{
  font-weight: normal;
  border-bottom: 3px #800000 solid;
}


.footPart p{
  margin: 0;
}

.footPart ul a{
  text-decoration: none;
  color: white;
}

.footPart input{
  margin-top: 20px;
}

#footer #Miscellaneous{
  color: grey;
  background-color: black;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1050px){
  .footPart{
    width: 40%;
  }
}

@media only screen and (max-width: 760px){
  .footPart{
    display: block;
    width: 90%;
    margin: auto; 
  }

  #footer{
    width: 100%;
  }
}

#Miscellaneous a{
  text-decoration: none;
  display: inline-block;
  margin: auto;
  text-align: center;
  color: white;
  background-color: black;
  text-align: center;
  width: 45%;
}

.social-container {
  background: black;
  padding: 25px 50px;
  width: 100%;
  margin: 0;
  box-sizing:border-box;

}

#news_email{
  margin-right: 20px;
}

.footPart #emails{
  vertical-align: top;
}

.footPart button{
  border:none;
  background-color: #800000;
  color:white;
  width: 90px;
  height:100%;
}


.footPart button:active{
  color:#800000;
  background-color: white;;
 }


a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

.social-container a{
  color:grey;
}

@media only screen and (max-width: 490px){
  .social-container{
    padding: 10px 0;
  }

  .footPart button{
    width: 70px;
  }
}



/* The next bit is the rich text editor */

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.editor{
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

  
.editor h2{
  text-align:center;
}



div.DraftEditor-root {
  border: 1px solid #000;
  min-height: 600px;
  overflow-y: auto;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.editor #articleTitle{
  font-size: 50px;
  margin-top: 20px;
  width: 100%;
  display: block;
  margin: auto;
}

div.rdw-editor-toolbar, div.rdw-option-wrapper, div.rdw-history-dropdown, div.rdw-dropdown-selectedtext, div.rdw-dropdown-wrapper{
  border: 1px solid #000;
}

div.rdw-editor-toolbar{
  background-color: orange;
}

div.rdw-editor-toolbar{
  margin-bottom: 0;
  border-bottom: none;
}

/*Color of selected option*/
li.rdw-dropdownoption-active {
  background: lightcoral;
  color: white;
}

#trueEditor{
  margin: 30px 0;
}

.editor #mainPhoto{
  margin-top: 30px;
}

.editor button{
  margin-right: 40px;
  text-align: center;
  padding: 0 10px;
}

.editor .blogError{
  color: red;
}

.editor #blogMianPicDisplay {
  width: 60%;
  margin: auto;
  display: block;
  text-align: center;
}

.editor h1.blogMianPicDisplayText{
  text-align: center;
  margin: 0;
}

.project{
  width: 100%;
  height: 550px;
}

.project img{
  background-clip: border-box;
  width: 50%;
  height: 550px;
  background-position: center center;
  float:right;
  object-fit: cover;
}

.project p{
  font-family: Arial, sans-serif;
  font-size: 17px;
  width: 100%;
  margin-bottom: 50px;
  width: 60%;
  vertical-align: bottom;
}

.projectText{
  width: 50%;
  display:inline-block;
  background-color: 	#ffd7b5;
  height: 550px;
  vertical-align: top;
}



.projectText h2{
  margin: 0;
}

.ProjectTextWrapper{
  display: table-cell;
  vertical-align: middle;
  height: 550px;
}


#loading{
  margin-bottom: 400px;
}

.project h2, .project p{
  margin: 0;
  text-align: center;
  margin:auto;
  vertical-align: middle;
}

.project h2{
  font-size: 40px;
  width: 80%;
}

.allProject #buttonwrap{
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 23%
}

.allProject #signupButton{
  margin:auto;
  text-align: center;
}

#CWENMeets img{
  width: 50%;
  height: 500px;
  margin: 10px 0;
}

#CWENMeets .projectText{
  height: 500px;
  margin-top: 10px;
  text-align: center;
}

#projectText .projectText{
  width: 75%;
  margin: auto;
}


#CWENMeets #signupButton{
  width: 75%;
}

#CWENMeets p, #CWENMeets h2{
  width: 75%;
  margin: auto;
}

#EofMonth #EofMonthFace{
  height: 700px;
  width: 500px;
  object-fit: cover;
  object-position: left top;
  display: inline-block;
  margin:auto;
  
  background-color: orange;
  border: 10px orange solid;
  border-radius: 10px;
}

#EofMonth{
  text-align: center;
  margin-top: 25px;
}

#EofMonth h2, #EofMonth h3{
  margin:0;
  font-size: 35px;
}

#EofMonthText h2{
  margin-top: 20px;
  font-size: 50px;
}

#EofMonthInfo{
  height: 700px;
  vertical-align: top;
  width: 50%;
  display: inline-block;
}

#EofMonth #monthBlurb{
  font-family: 'Arial Narrow', Arial, sans-serif;
  width: 80%;
  margin: auto;
  font-size: 25px;
}

@media only screen and (max-width: 1060px){
  #EofMonthInfo{
    height: auto;
    margin: auto;
    display: block;
  }
}


@media only screen and (max-width: 910px){
  .projectText{
    width:100%;
    height: auto;
    padding-bottom: 20px;
  }

  .ProjectTextWrapper{
    height:auto;
  }
  
  .project img{
    width: 100%;
    height: auto;
  }

  .allProject #signupButton{
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }

  .allProject #buttonwrap{
    width: 50%;
  }

  #CWENMeets img{
    width: 100%;
    height: auto;
    margin-top: 0px;
  }

  #CWENMeets .projectText{
    height: auto;
    margin-top: 30px;
    padding: 20px;
  }

  #EofMonthInfo{
    width: 90%;
    height: auto;
  }

  #EofMonth #rot-buttons a, #rot-text h2{
    font-size: 40px;
  }

  .project{
    height: auto;
  }

  .project h2{
    width: 90%;
    margin-bottom: 10px;
  }

  .project p{
    width: 85%;
  }
}

@media only screen and (max-width: 590px){
  #EofMonth #EofMonthFace{
    width: 80%;
    height: auto;
  }
}

/*Styling for Login Box*/
.Box{
  width: 40%;
  margin: 0 auto;
  margin-top:30px;
}

.Box h2{
  padding:0;
  width: 30%;
  color:white;
  margin: 0 auto;
  text-align: center;
}

.Box .BoxTop{
  background-color:#282828;
}

.Box .BoxBottom{
  padding:10px;
  background-color:#e6b167;
  border: solid;
}

.Box .BoxForms{
  margin-bottom: 20px;
}

.Box plaintext{
  display: inline-block;
  margin: 10px;
}

.Box input{
  width:99%;
}

.Box button{
  border: solid;
  background-color: white;
  border-radius: 10px;
  
}

.Box #BoxError{
  font-size: 100%;
  color:red;
  margin: 0;
}

.Box button:focus{
  outline: none;
  box-shadow: none;
}

.Box button:active{
  background:red;
}

.Box button, .Box plaintext{
  font-size: 200%;
}

.Box .inputWrapper{
  margin: auto;
}

.Box .LoginButtonOuter{
  width:33%;
  margin:auto;
  display:inline-block;
}





.Box .LoginButtonInner{
  width:75%;
  margin:auto;
}



.Box .SignupButtonOuter{
  width:100%;
  display:inline-block;
}

.Box .SignupButtonInner button{
  width:100%;
  margin:auto;
}

#ResetRequestButtons{
  width: 100%;
}

#ResetRequestButtons .LoginButtonInner{
  width: 30%;
  margin: auto;
}

.loggedInInfo, .generic{
  margin-bottom: 220px;
}

#AboutUs{
  width: 80%;
  margin: auto;
}

#AboutUs img{
  display: block;
  margin: auto;
  width: 50%;
}

#teamMembers{
  width: 95%;
  text-align: center;
}

#teamMembers .teamMember{
  padding-top: 30px;
  width: 30%;
  display: inline-block;
  margin: auto;
  text-align: center;
}

#teamMembers .teamMember img{
  height: 300px;
  width: 240px;
  object-fit: cover; 
}

#teamMembers .teamMember h2, #teamMembers .teamMember h3{
  margin: 0;
}




@media only screen and (max-width: 1080px){
  #teamMembers .teamMember{
    display: block;
  }

  #teamMembers .teamMember{
    width: 100%;
  }
}

#services{
  width: 60%;
  margin:auto;
  margin-bottom: 90px;
  text-align: justify;
}

#services #serviceImg{
  width: 90%;
  margin: auto;
  display: block;
}

#services p, #services h2{
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-size: 40px;
}

#services p{
  font-size: 25px;
}

#editMonth{
  width: 80%;
  margin: auto;
}

#editMonth #portrait{
  height: 300px;
}

#editMonth h2, #editMonth h3{
  margin:0;
  font-size: 35px;
}

#editMonth #monthProducts{
  width: 50%;
}

#editMonth #rotation{
  margin-left: 0px;
}

#monthPreview #previewButton{
  width: 85%;
  margin: auto;
}



/*Join */

/*Remove Arrows*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

#joinPage{
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

#joinInfo{
  height: 100%;
  vertical-align: top;
  background-color: orange;
}

#joinTop{
  background-color: #800000;
}

#joinTop h3{
  width: 100%;
  text-align: center;
  color: white;
  margin: 5px 0;
}

#joinWrapper{
  width: 90%;
  margin: auto;
}

#joinInfo input{
  width: 100%;
}


#joinInfo #descrption{
  display:inline-block;
  width: 100%;
}

#joinDecoration, #joinInfo{
  display: inline-block;
  width: 50%;
}

#joinDecoration img{
  width: 60%;
  margin: auto;
  display: block;
}

#joinInfo #submitLink{
  display: block;
  margin: auto;
  width: 10%;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #800000;
  color:white;
  text-align: center;
  border-radius: 3px;
}

#joinInfo #submitLink:active{
  background-color: white;
  color:#800000;
}

#joinPage #errors{
  width: 100%;
  background-color: white;
  color: red;
}

#joinDecoration h3{
  text-align: center;
}


#joinDecoration p{
  width: 90%;
  margin: auto;
  margin-bottom: 10px;
}

#errors p{
  margin-bottom: 0;
}


#joinInfo h3{
  margin: 0;
}

@media only screen and (max-width: 900px){
  #joinDecoration, #joinInfo{
    display: block;
    width: 90%;
    margin: auto;
  }

  #joinWrapper{
    padding-bottom: 10px;
  }

  #joinInfo #submitLink{
    width: 100%;
  }

  #joinPage #errors{
    border: white 3px solid;
  }

  #joinDecoration p{
    margin-bottom: 30px;
  }
}

#contactPage{
  width: 80%;
  margin: auto;
}

.contactFormTop{
  display: inline-block;
  width: 50%;
}

.contactFormTop input{
  width: 90%;

}

.contactFormTop #Email{
  width: 100%;
}

#contactForm #Topic{
  width: 100%;
  margin: 0;
}

#contactForm textarea{
  width: 100%;
}

#contactPage #submitLink{
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #800000;
  color:white;
  border-radius: 3px;
  width: 10%;
  padding: 3px;
  text-align: center;
}

#contactPage #submitLink:active{
  background-color: orange;
  color:#800000;
}

#Writing_Center, #blogs{
  width: 80%;
  margin: auto;
  margin-top: 40px;
}

#Writing_Center button{
  margin-bottom: 50px;
  margin-top: 20px;
}

.blogPart{
  width: 33%;
  margin: auto;
  display: inline-block;
  margin-top: 30px;
}

.blogPart img{
  width: 90%;
  display: block;
  margin: auto;
}

.blogPart h2{
  text-align: center;
}


#blog{
  width: 90%;
  margin: auto;
}

#blog img{
  max-width: 100%;
}

@media only screen and (max-width: 800px){
  #contactPage #submitLink{
    width: 25%;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
